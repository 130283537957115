import { footerTheme } from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import { makeButtonStyles } from 'apputil/button-styles';

const buttonStyles = makeButtonStyles(footerTheme);

const baseFooter = {
  bottom: 0,
  width: '100%',
  paddingTop: footerTheme.spacing(0.75),
  paddingBottom: footerTheme.spacing(0.75),
  paddingLeft: footerTheme.spacing(1.5),
  paddingRight: footerTheme.spacing(1.5),
  [footerTheme.breakpoints.down('md')]: {
    paddingTop: footerTheme.spacing(0.75),
    paddingBottom: footerTheme.spacing(0.75),
    paddingLeft: footerTheme.spacing(1),
    paddingRight: footerTheme.spacing(1),
  },
  [footerTheme.breakpoints.down('sm')]: {
    padding: footerTheme.spacing(0.75),
  },
  marginTop: 'auto',
  marginBottom: footerTheme.spacing(2),
};

const darkFooter = {
  ...baseFooter,
  backgroundColor: '#333333',
};

const lightFooter = {
  ...baseFooter,
  backgroundColor: '#F5F5F5',
};

const footerBreaks = {
  [footerTheme.breakpoints.down('lg')]: {
    left: '4%',
    width: '96%',
  },
  [footerTheme.breakpoints.down('md')]: {
    left: '5%',
    width: '95%',
  },
  [footerTheme.breakpoints.down('sm')]: {
    left: '0',
    width: '100%',
  },
};

export default makeStyles({
  ...buttonStyles,
  noBottomMargin: {
    paddingBottom: '0 !important',
    marginBottom: '0 !important',
  },
  icon: {
    [footerTheme.breakpoints.down('sm')]: {
      fontSize: footerTheme.typography.fontSize * 1.5,
    },
  },
  bottomNavContainer: {
    backgroundColor: footerTheme.palette.background.default,
  },
  bottomNav: {
    [footerTheme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  container: {
    [footerTheme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  darkFooter: {
    ...darkFooter,
  },
  lightFooter: {
    ...lightFooter,
  },
  stickyWideDarkFooter: {
    ...darkFooter,
    left: '2%',
    width: '96%',
    position: 'fixed',
  },
  stickyDarkFooter: {
    ...darkFooter,
    left: '3.5%',
    width: '96.5%',
    position: 'fixed',
    ...footerBreaks,
  },
  stickyLightFooter: {
    ...lightFooter,
    left: '3.5%',
    width: '96.5%',
    position: 'fixed',
    ...footerBreaks,
  },
  circularProgress: {
    left: 'calc(50% - 60px)',
    position: 'relative',
  },
});

export const useToolTipClasses = makeStyles({
  tooltip: { margin: footerTheme.spacing(0) },
});
